import PageWrapper from '../page-wrapper/pageWrapper';
import React from 'react';
import Card from '../card/card';
import { Box, Flex } from '@chakra-ui/react';
import CompleteUserProfileForm from './completeUserProfileForm';

type Props = {
  onProfileCompleted?: () => void;
};

export const CompleteProfilePage: React.FC<Props> = ({ onProfileCompleted }) => {
  return (
    <PageWrapper>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" gap={4} py={8} px={4}>
        <Box fontSize="3xl" fontWeight="bold">
          Complete profile setup
        </Box>
        <Card maxW="container.sm">
          <CompleteUserProfileForm onProfileCompleted={onProfileCompleted} />
        </Card>
      </Flex>
    </PageWrapper>
  );
};