import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queryKeys from './queryKeys';

export const useMonitorTableMenuColumnsQuery = (name: string) => {
  return useQuery(
    queryKeys.monitorTableMenuColumns(name),
    () => {
      const key = `monitor-table-menu-column-${name}`;
      const columnsValue = window.localStorage.getItem(key);

      if (!columnsValue) {
        return null;
      }

      return JSON.parse(columnsValue) as { [key: string]: boolean };
    },
    {
      keepPreviousData: true,
      refetchOnMount: true,
      initialData: () => {
        const key = `monitor-table-menu-column-${name}`;
        const filtersValue = window.localStorage.getItem(key);

        if (!filtersValue) {
          const defaultFilters = {
            responseTime: true,
            uptime: true,
            sslExpiry: true
          };
          window.localStorage.setItem(key, JSON.stringify(defaultFilters));
          return defaultFilters;
        }
        return JSON.parse(filtersValue) as { [key: string]: boolean };
      }
    }
  );
};

export const useMonitorTableMenuColumnsMutation = (name: string) => {
  const queryClient = useQueryClient();
  const key = `monitor-table-menu-column-${name}`;

  return useMutation<any, any, { columns: { [key: string]: boolean } }>(
    async ({ columns }) => {
      window.localStorage.setItem(key, JSON.stringify(columns));
    },
    { onSuccess: () => queryClient.invalidateQueries(queryKeys.monitorTableMenuColumns(name)) }
  );
};
