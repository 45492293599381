import {
  Alert,
  AlertIcon,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { UpgradeModalReason } from '../../app/model/upgradeModalReason';
import actions from '../../app/actions';
import AppPricing from '../pricing/appPricing';

const UpgradeModal = () => {
  const dispatch = useAppDispatch();
  const {
    app: { initialized },
    upgrade: { showUpgradeModal, reason },
  } = useAppSelector((state) => state);

  if (!initialized) {
    return null;
  }

  const onClose = () => {
    dispatch(actions.upgrade.closeUpgradeModal());
  };

  return (
    <Modal isOpen={showUpgradeModal} onClose={onClose} size="6xl" closeOnEsc>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center" justifyContent="center" textAlign="center" w="100%" fontSize="2xl" fontWeight="bold">
            Organization plan
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {reason && (
            <Flex alignItems="center" justifyContent="center" mb={8}>
              <Alert status="warning" borderRadius="lg">
                <AlertIcon />
                <Text fontSize="lg">
                  {reason === UpgradeModalReason.MONITOR_QUOTA && (
                    <>You have reached the maximum number of monitors in your organization. Please upgrade the plan to create more monitors.</>
                  )}
                  {reason === UpgradeModalReason.USER_QUOTA && (
                    <>You've reached the maximum number of users in your organization. Upgrade to invite more users.</>
                  )}
                  {reason === UpgradeModalReason.SMS_INTEGRATION_NOT_AVAILABLE && (
                    <>SMS integration is not available on free plan. Upgrade to be notified of incidents through SMS messages.</>
                  )}
                  {reason === UpgradeModalReason.DOMAIN_NAME_CHECK_NOT_AVAILABLE && (
                    <>Domain name monitoring is not available on free plan. Upgrade to be notified before your domain name expires.</>
                  )}
                  {reason === UpgradeModalReason.STATUS_PAGE_LIMIT_REACHED && (
                    <>You've reached the maximum number of status pages in your organization. Upgrade to create more status pages.</>
                  )}
                  {reason === UpgradeModalReason.STATUS_PAGE_CUSTOM_DOMAIN_NOT_AVAILABLE && (
                    <>Status page custom domains are not available in your current plan. Upgrade to set custom domains to your status pages.</>
                  )}
                  {reason === UpgradeModalReason.STATUS_PAGE_PASSWORD_PROTECTED_NOT_AVAILABLE && (
                    <>Status page password protection is not available in your current plan. Upgrade to password protect to your status pages.</>
                  )}
                  {reason === UpgradeModalReason.STATUS_PAGE_DISABLE_BRANDING_NOT_AVAILABLE && (
                    <>You cannot disable Status Ping branding from status pages on your current plan. Upgrade to remove branding from status pages.</>
                  )}
                  {reason === UpgradeModalReason.ON_CALL_SCHEDULES_NOT_AVAILABLE && (
                    <>You cannot use on-call schedules on your current plan. Upgrade to use on-call schedules.</>
                  )}
                  {reason === UpgradeModalReason.ON_CALL_SCHEDULES_QUOTA && (
                    <>You have reached the limit of on-call schedules on your current plan. Upgrade to create more on-call schedules.</>
                  )}
                </Text>
              </Alert>
            </Flex>
          )}
          <AppPricing />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default UpgradeModal;
