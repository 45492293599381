import React, { useState } from 'react';
import Routes from '../../utils/routes';
import AppLink from '../app-link/appLink';
import Card from '../card/card';
import { Alert, AlertIcon, Box, Button, Flex, Icon, Input } from '@chakra-ui/react';
import { AiOutlineGoogle } from 'react-icons/ai';
import { SiMicrosoft } from 'react-icons/si';


type Props = {
  onLogin: (username: string, password: string) => void;
  onLoginUsingGoogle: () => void;
  onLoginUsingMicrosoft: () => void;
  disabled: boolean;
  loading: boolean;
  error?: string;
  success?: string;
};

export const LoginForm: React.FC<Props> = ({ onLogin, onLoginUsingGoogle, onLoginUsingMicrosoft, disabled, loading, error, success }) => {
  const [loadingState, setLoadingState] = useState({
    google: false,
    microsoft: false,
    login:false
  });

  const handleMicrosoftLogin = async () => {
    setLoadingState({ ...loadingState, microsoft: true });
    try {
      await onLoginUsingMicrosoft();
    } finally {
      //setLoadingState({ ...loadingState, microsoft: false });
    }
  };

  const handleGoogleLogin = async () => {
    setLoadingState({ ...loadingState, google: true });
    try {
      await onLoginUsingGoogle();
    } finally {
      //setLoadingState({ ...loadingState, google: false });
    }
  };
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (evt) => {
    evt.preventDefault();
  
    setLoadingState({ ...loadingState, login: true });
  
    try {
      await onLogin(email, password);
      // Handle invalid email or password scenario
      setLoadingState({ ...loadingState, login: false });
    } catch (error) {
      // Handle unexpected errors
      setLoadingState({ ...loadingState, login: false });
      console.error("Login failed:", error);
    }
  };
  
  return (
    <Flex minH="100vh" alignItems="center" justifyContent="center" py={12} px={4} backgroundColor="gray.200">
      <Flex maxW="container.xs" width="100%" flexDirection="column" gap={6}>
        <Box as="img" mx={'auto'} w={'auto'} maxHeight="64px" src="/logo.png" alt="Status Ping" />
        <Card>
          <Box mb={2}>
            <Box as="h2" fontSize="3xl" textAlign="center" fontWeight="bold">
              Sign in to your account
            </Box>
          </Box>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          {success && (
            <Alert status="success">
              <AlertIcon />
              {success}
            </Alert>
          )}
          <Button w="100%" size="lg" backgroundColor="#DB4437" colorScheme='red' color="white" onClick={handleGoogleLogin}
        isDisabled={disabled || loadingState.google}
        isLoading={loadingState.google}>
            <Icon as={AiOutlineGoogle} boxSize={6} mr={2} />
            Sign in using Google
          </Button><br/>
            <Button w="100%" size="lg" backgroundColor="#00A4EF" color="white" colorScheme='blue' onClick={handleMicrosoftLogin}
        isDisabled={disabled || loadingState.microsoft}
        isLoading={loadingState.microsoft}>
            <Icon as={SiMicrosoft} boxSize={6} mr={2} />
            Sign in using Microsoft
            </Button>
          <Box textAlign="center" fontSize="lg" my={4}>
            {/* or using email and password */}
            Access via Email and Password
          </Box>
          <Flex as="form" flexDirection="column" gap={6} onSubmit={handleSubmit}>
            <Flex flexDirection="column" gap={2} borderRadius="md" boxShadow={'sm'}>
              <div>
                <Input placeholder="Email address" type="email" onChange={(evt) => setEmail(evt.target.value)} value={email} />
              </div>
              <div>
                <Input placeholder="Password" type="password" onChange={(evt) => setPassword(evt.target.value)} value={password} />
              </div>
            </Flex>

            

            <div>
              <Button type="submit" size="lg" w="50%" colorScheme="blue" isDisabled={disabled} isLoading={loadingState.login}>
                Sign in
              </Button>
                  <AppLink to={Routes.FORGOT_PASSWORD} style={{ margin: '10px', float: 'right' }}>Forgot your password?</AppLink>
            </div>
          </Flex>
          <Box textAlign="center" mt={4}>
            {`Don't have an account? `}
            <AppLink to={Routes.SIGN_UP}>Sign up</AppLink>
          </Box>
        </Card>
      </Flex>
    </Flex>
  );
};

export default LoginForm;
