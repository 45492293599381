import PageWrapper from '../page-wrapper/pageWrapper';
import React, { useEffect, useState } from 'react';
import Card from '../card/card';
import { Box, color, Flex } from '@chakra-ui/react';
import CompleteUserOrgForm from './completeUserOrgForm';
import { useNavigate } from 'react-router-dom';
import Routes from '../../utils/routes';
import statusPingClient from '../../app/client/statusPingClient';
import Fallback from '../fallback/fallback';

type Props = {
  onOrgCompleted?: () => void;
};

export const CompleteOrgPage: React.FC<Props> = ({ onOrgCompleted }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [countdown, setCountdown] = useState(10); // 10 seconds countdown
  const navigate = useNavigate();
  
const handleLogout = async () => {
  await statusPingClient.protectedGetLogout();
  window.location.href = Routes.LOGIN;
};


  useEffect(() => {
    setShowNotification(true);

    const timer = setTimeout(async () => {
      await statusPingClient.protectedGetLogout();
      window.location.href = Routes.LOGIN;
    }, 10000);

    const interval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  return (
    <Box>
      {showNotification && (
        <div style={{ textAlign: 'center', color: 'red' }}>
          <Fallback header="" subheader={<><h2 style={{ fontSize: '24px', color:'red', margin:10}}>To access Status Ping, Please use your organization email ID</h2><a style={{ color: '#4299e1' }} onClick={handleLogout}>Click here</a> to proceed to the sign-in or sign-up page, or wait 10 seconds for the page to redirect automatically.</>} />
          Redirecting in {countdown} seconds.
        </div>
      )}
     
    </Box>
  );
};
