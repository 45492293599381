import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import {
  MonitorTransport,
} from '@um/uptime-monitoring-shared';

export const useMonitorTableMenuFiltersQuery = (name: string) => {
  return useQuery(
    queryKeys.monitorTableMenuFilters(name),
    () => {
      const key = `monitor-table-menu-filters-${name}`;
      const filtersValue = window.localStorage.getItem(key);

      if (!filtersValue) {
        return null;
      }

      return JSON.parse(filtersValue) as { [key: string]: boolean };
    },
    {
      keepPreviousData: true,
      refetchOnMount: true,
      initialData: () => {
        const key = `monitor-table-menu-filters-${name}`;
        const filtersValue = window.localStorage.getItem(key);

        if (!filtersValue) {
          const defaultFilters = {};
          Object.values(MonitorTransport).forEach((transport) => {
            defaultFilters[transport] = true;
          });
          window.localStorage.setItem(key, JSON.stringify(defaultFilters));
          return defaultFilters;
        }
        return JSON.parse(filtersValue) as { [key: string]: boolean };
      }
    },
  );
};

export const useMonitorTableMenuFiltersMutation = (name: string) => {
  const queryClient = useQueryClient();
  const key = `monitor-table-menu-filters-${name}`;

  return useMutation<any, any, { filters: { [key: string]: boolean } }>(
    async ({ filters }) => {
      window.localStorage.setItem(key, JSON.stringify(filters));
    },
    { onSuccess: () => queryClient.invalidateQueries(queryKeys.monitorTableMenuFilters(name)) }
  );
};
