import './styles/globals.css';
import './styles/mdx.css';
import 'rc-slider/assets/index.css';
import 'dygraphs/dist/dygraph.min.css';
import React, { lazy, useEffect, useMemo } from 'react';
import Layout from './components/layout/layout';
import { Provider, useDispatch } from 'react-redux';
import store, { useAppSelector } from './app/store';
import { Toaster } from 'react-hot-toast';
import actions from './app/actions';
import Routes, { RouteSlugs } from './utils/routes';
import { BrowserRouter, matchRoutes, Navigate, Route, Routes as RouterRoutes, useLocation, useNavigate } from 'react-router-dom';
import MonitorsPage from './pages/monitorsPage';
import LoginPage from './pages/login';
import SignUpPage from './pages/sign-up';
import VerifyEmailAddressPage from './pages/verify-email-address';
import ResetPasswordPage from './pages/reset-password';
import ForgotPasswordPage from './pages/forgot-password';
import EditNotificationIntegrationPage from './pages/notification-integrations/editNotificationIntegrationPage';
import NotificationsPage, {
  NotificationPoliciesOutlet,
  NotificationsIntegrationsOutlet,
  NotificationsOverviewOutlet,
} from './pages/notifications/notificationsPage';
import MonitorDetailsPage, {
  MonitorDetailsOverviewOutlet,
  MonitorDetailsSettingsOutlet,
  OVERVIEW_SLUG,
  SETTINGS_SLUG,
} from './pages/monitors/monitorDetailsPage';
import { ChakraProvider, Container } from '@chakra-ui/react';
import { websiteTheme } from './utils/websiteTheme';
import { AdminPanelModal } from './components/admin-panel/adminPanel';
import selectors from './app/selectors';
import UsersPage from './pages/usersPage';
import OrganizationPage from './pages/organizationPage';
import UpgradeModal from './components/upgrade-modal/upgradeModal';
import SubscriptionPage from './pages/organization/subscription';
import UserPage from './pages/user';
import UserNotificationSettingsPage from './pages/user-notification-settings';
import { CompleteProfilePage } from './components/complete-profile/completeProfilePage';
import { CompleteOrgPage } from './components/complete-org/completeOrgPage';

import IntegrationsPage from './pages/notification-integrations/integrationsPage';
import MonitorGroupsPage from './pages/monitor-groups/monitorGroupsPage';
import CreateMonitorGroupPage from './pages/monitor-groups/createMonitorGroupPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MonitorGroupPage, { MonitorGroupDetailsOverviewOutlet, MonitorGroupDetailsSettingsOutlet } from './pages/monitor-groups/monitorGroupPage';
import Fallback from './components/fallback/fallback';
import handleQueryClientWebsocketEvents from './query/handleQueryClientWebsocketEvents';
import CreateStatusPagePage from './pages/status-pages/createStatusPagePage';
import StatusPagesPage from './pages/status-pages/statusPagesPage';
import StatusPagePage, { StatusPagePageIncidentsOutlet, StatusPagePageSettingsOutlet } from './pages/status-pages/statusPagePage';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import { CompleteOrganizationSetupPage } from './components/complete-organization-setup/completeOrganizationSetupPage';
import { useSelectedOrganizationQuery } from './query/organizationQueries';
import Spinner from './components/spinner/spinner';
import EditNotificationPolicyPage from './pages/notification-policy/editNotificationPolicyPage';
import CreateNotificationIntegrationPage from './pages/notification-integrations/createNotificationIntegrationPage';
import CreateNotificationPolicyPage from './pages/notification-policy/createNotificationPolicyPage';
import SettingsPage from './pages/settings/settingsPage';
import NotificationPoliciesPage from './pages/notification-policy/notificationPoliciesPage';
import OnCallSchedulesPage from './pages/on-call-schedule/onCallSchedulesPage';
import CreateOnCallSchedulePage from './pages/on-call-schedule/createOnCallSchedulePage';
import OnCallSchedulePage, { OnCallScheduleOverviewOutlet, OnCallScheduleSettingsOutlet } from './pages/on-call-schedule/onCallSchedulePage';
import { QueryInvalidator, QueryInvalidatorContext } from './query/queryInvalidatorContext';
import StatusPageIncidentPage from './pages/status-pages/statusPageIncidentPage';
import CreateStatusPageIncidentPage from './pages/status-pages/createStatusPageIncidentPage';
import CookieConsentBanner, { CookieConsentProvider } from './components/cookie-consent/cookieConsent';
import useAmplitude from './hooks/useAmplitude';

const IncidentsPage = lazy(() => import('./pages/incidents/incidentsPage'));
const IncidentDetailsPage = lazy(() => import('./pages/incidents/incidentDetailsPage'));
const NotificationDetailsPage = lazy(() => import('./pages/notification/notificationDetailsPage'));
const MonitorCheckDetailsPage = lazy(() => import('./pages/monitorCheckDetailsPage'));
const CreateMonitorPage = lazy(() => import('./pages/monitors/createMonitorPage'));

const UNPROTECTED_ROUTES = [
  Routes.LOGIN,
  Routes.SIGN_UP,
  Routes.FORGOT_PASSWORD,
  Routes.RESET_PASSWORD,
  Routes.VERIFY_EMAIL_ADDRESS,
  Routes.USER_NOTIFICATION_SETTINGS,
];
const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 3000 } } });
const queryInvalidator = new QueryInvalidator(queryClient);

handleQueryClientWebsocketEvents(queryInvalidator);

const AppWithStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { initialized, initializing, user, showAdmin, initializationError } = useAppSelector((state) => state.app);
  const admin = useAppSelector(selectors.selectIsAdmin);
  const unprotectedRoutes = useMemo(
    () =>
      matchRoutes(
        UNPROTECTED_ROUTES.map((path) => ({ path })),
        pathname
      ),
    [pathname]
  );
  const isUnprotectedRoute = useMemo(() => unprotectedRoutes?.length > 0, [unprotectedRoutes]);
  const { isLoading, data: selectedOrganization } = useSelectedOrganizationQuery(!isUnprotectedRoute);

  useEffect(() => {
    if (!initialized && !initializing) {
      dispatch(actions.app.initialize());
    }

    if (initialized && !user && !isUnprotectedRoute) {
      navigate(Routes.LOGIN);
    }
  }, [initialized, initializing, user, isUnprotectedRoute]);
  useAmplitude();
  console.log("USER::::::",user);
  const profileSetupRequired = useMemo(() => user && user.profileSetupRequired, [user]);
  const orgSetupRequired = useMemo(() => user && user.orgSetupRequired, [user]);

  

  const organizationSetupRequired = useMemo(() => selectedOrganization && selectedOrganization.organizationSetupRequired, [selectedOrganization]);
  const onSetupCompleted = () => {
    dispatch(actions.app.setInitialized(false));
  };
  const loading = useMemo(() => isLoading && !isUnprotectedRoute, [isLoading, isUnprotectedRoute]);

  if (isUnprotectedRoute) {
    return (
      <RouterRoutes>
        <Route path={Routes.LOGIN} element={<LoginPage />} />
        <Route path={Routes.SIGN_UP} element={<SignUpPage />} />
        <Route path={Routes.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={Routes.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={Routes.VERIFY_EMAIL_ADDRESS} element={<VerifyEmailAddressPage />} />
        <Route path={Routes.USER_NOTIFICATION_SETTINGS} element={<UserNotificationSettingsPage />} />
      </RouterRoutes>
    );
  }

  return (
    <>
      <Container
        position="absolute"
        left="0"
        top="0"
        width="100%"
        maxW="100%"
        height="100%"
        minH="100vh"
        zIndex="-1"
        opacity="0.4"
        backgroundColor="gray.200"
        display="block"
      />
      <Layout active hideSidebar={!initialized || profileSetupRequired || organizationSetupRequired}>
        {loading && <Spinner mt={6} />}
        {!loading && initializationError && !isUnprotectedRoute && (
          <Fallback header="Error initializing application" subheader={<>Please stand by while we retry loading or try again later</>} />
        )}
        {!loading && !initializationError && (
          <>
            {admin && <AdminPanelModal open={showAdmin} onClose={() => dispatch(actions.app.setShowAdmin(false))} />}
            <UpgradeModal />
            {orgSetupRequired && !initializationError && <CompleteOrgPage onOrgCompleted={onSetupCompleted} />}
            {profileSetupRequired && !orgSetupRequired && !initializationError && <CompleteProfilePage onProfileCompleted={onSetupCompleted} />}

            {!profileSetupRequired && organizationSetupRequired && !initializationError && (
              <CompleteOrganizationSetupPage onSetupCompleted={onSetupCompleted} />
            )}
            {initialized && !initializationError && !profileSetupRequired && !organizationSetupRequired && (
              <RouterRoutes>
                <Route path="/" element={<Navigate to={Routes.MONITORS} replace={true} />} />
                <Route path={Routes.MONITOR_GROUPS} element={<MonitorGroupsPage />} />
                <Route path={Routes.CREATE_MONITOR_GROUP} element={<CreateMonitorGroupPage />} />
                <Route path={Routes.MONITOR_GROUP} element={<MonitorGroupPage />}>
                  <Route path={OVERVIEW_SLUG} element={<MonitorGroupDetailsOverviewOutlet />} />
                  <Route path={SETTINGS_SLUG} element={<MonitorGroupDetailsSettingsOutlet />} />
                </Route>
                <Route path={Routes.MONITORS} element={<MonitorsPage />} />
                <Route path={Routes.MONITOR_CHECK_DETAILS} element={<MonitorCheckDetailsPage />} />
                <Route path={Routes.CREATE_MONITOR} element={<CreateMonitorPage />} />
                <Route path={Routes.MONITOR_DETAILS} element={<MonitorDetailsPage />}>
                  <Route path={OVERVIEW_SLUG} element={<MonitorDetailsOverviewOutlet />} />
                  <Route path={SETTINGS_SLUG} element={<MonitorDetailsSettingsOutlet />} />
                </Route>
                <Route path={Routes.INCIDENTS} element={<IncidentsPage />} />
                <Route path={Routes.INCIDENT_DETAILS} element={<IncidentDetailsPage />} />
                <Route path={Routes.ORGANIZATION} element={<OrganizationPage />} />
                <Route path={Routes.USER} element={<UserPage />} />
                <Route path={Routes.NOTIFICATION_DETAILS} element={<NotificationDetailsPage />} />
                <Route path={Routes.NOTIFICATIONS} element={<NotificationsPage />}>
                  <Route path={RouteSlugs.OVERVIEW} element={<NotificationsOverviewOutlet />} />
                  <Route path={RouteSlugs.INTEGRATIONS} element={<NotificationsIntegrationsOutlet />} />
                  <Route path={RouteSlugs.POLICIES} element={<NotificationPoliciesOutlet />} />
                </Route>
                <Route path={Routes.NOTIFICATION_INTEGRATIONS} element={<IntegrationsPage />} />
                <Route path={Routes.NOTIFICATION_INTEGRATION} element={<EditNotificationIntegrationPage />} />
                <Route path={Routes.NOTIFICATIONS_POLICIES} element={<NotificationPoliciesPage />} />
                <Route path={Routes.CREATE_NOTIFICATION_POLICY} element={<CreateNotificationPolicyPage />} />
                <Route path={Routes.NOTIFICATIONS_POLICY_DETAILS} element={<EditNotificationPolicyPage />} />
                <Route path={Routes.CREATE_NOTIFICATION_INTEGRATION} element={<CreateNotificationIntegrationPage />} />
                <Route path={Routes.USERS} element={<UsersPage />} />
                <Route path={Routes.SUBSCRIPTION} element={<SubscriptionPage />} />
                <Route path={Routes.STATUS_PAGES} element={<StatusPagesPage />} />
                <Route path={Routes.CREATE_STATUS_PAGE} element={<CreateStatusPagePage />} />
                <Route path={Routes.SETTINGS} element={<SettingsPage />} />
                <Route path={Routes.ON_CALL_SCHEDULES} element={<OnCallSchedulesPage />} />
                <Route path={Routes.CREATE_ON_CALL_SCHEDULE} element={<CreateOnCallSchedulePage />} />
                <Route path={Routes.ON_CALL_SCHEDULE} element={<OnCallSchedulePage />}>
                  <Route path={RouteSlugs.OVERVIEW} element={<OnCallScheduleOverviewOutlet />} />
                  <Route path={RouteSlugs.SETTINGS} element={<OnCallScheduleSettingsOutlet />} />
                </Route>
                <Route path={Routes.STATUS_PAGE_INCIDENT} element={<StatusPageIncidentPage />} />
                <Route path={Routes.CREATE_STATUS_PAGE_INCIDENT} element={<CreateStatusPageIncidentPage />} />
                <Route path={Routes.STATUS_PAGE} element={<StatusPagePage />}>
                  <Route path={RouteSlugs.SETTINGS} element={<StatusPagePageSettingsOutlet />} />
                  <Route path={RouteSlugs.INCIDENTS} element={<StatusPagePageIncidentsOutlet />} />
                </Route>
              </RouterRoutes>
            )}
          </>
        )}
      </Layout>
    </>
  );
};

const MyApp = () => {
  return (
    <Provider store={store}>
      <QueryInvalidatorContext.Provider value={{ queryInvalidator }}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <CookieConsentProvider>
              <ChakraProvider theme={websiteTheme}>
                <AppWithStore />
                <Toaster
                  toastOptions={{
                    duration: 5000,
                    position: 'top-center',
                    style: { maxWidth: '600px' },
                  }}
                />
                <CookieConsentBanner />
              </ChakraProvider>
            </CookieConsentProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </QueryInvalidatorContext.Provider>
    </Provider>
  );
};

export default MyApp;
