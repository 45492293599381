import LoginForm from '../components/login-form/loginForm';
import { useEffect, useMemo, useState } from 'react';
import { operationWrapper } from '../utils/operationWrapper';
import statusPingClient from '../app/client/statusPingClient';
import Routes from '../utils/routes';
import { useAppDispatch, useAppSelector } from '../app/store';
import { toast } from 'react-hot-toast';
import actions from '../app/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { dismissibleToast } from '../utils/dismissibleToast';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { initialized, user } = useAppSelector((state) => state.app);
  const token = useMemo(() => searchParams.get('token') as string, [searchParams.get('token')]);

  useEffect(() => {
    if (initialized && user) {
      navigate(Routes.MONITORS);
    }
  }, [initialized, user]);

  useEffect(() => {
    if (!token) {
      return;
    }

    const login = operationWrapper(
      async () => {
        setLoading(true);
        setDisabled(true);

        await statusPingClient.publicPostLogin({ token });
      },
      {
        successCallback: () => {
          dispatch(actions.app.loggedIn());
        },
        errorCallback: (e: any) => {
          setLoading(false);
          setDisabled(false);

          const errorMessage = e?.response?.data?.message;
          toast.error(dismissibleToast(errorMessage || 'Login link invalid'), {
            duration: 99999999,
          });
          navigate(Routes.LOGIN);
        },
      }
    );

    login();
  }, [token]);

  const onLogin = async (username: string, password: string) => {
    const login = operationWrapper(
      async () => {
        setLoading(true);
        setDisabled(true);

        await statusPingClient.publicPostLogin({ username, password });
      },
      {
        successCallback: () => {
          toast.success('Logged in successfully. Please wait while we redirect you');

          dispatch(actions.app.loggedIn());
        },
        errorCallback: (e: any) => {
          setLoading(false);
          setDisabled(false);

          const errorMessage = e?.response?.data?.message;
          toast.error(dismissibleToast(errorMessage || 'Error occurred please try again later'), {
            duration: 99999999,
          });
        },
      }
    );

    await login();
  };

  const onLoginUsingGoogle = async () => {
    try {
      setLoading(true);
      setDisabled(true);

      window.location.href = Routes.GOOGLE_AUTH;
    } catch (e: any) {
      setLoading(false);
      setDisabled(false);

      const errorMessage = e?.response?.data?.message;
      setError(errorMessage || 'Error occurred please try again later');
    }
  };

  const onLoginUsingMicrosoft = async () => {
    try {
      setLoading(true);
      setDisabled(true);

      window.location.href = Routes.MICROSOFT_AUTH;
    } catch (e: any) {
      setLoading(false);
      setDisabled(false);

      const errorMessage = e?.response?.data?.message;
      setError(errorMessage || 'Error occurred please try again later');
    }
  };

  if (initialized && user) {
    return null;
  }

  return (
    <LoginForm onLogin={onLogin} onLoginUsingGoogle={onLoginUsingGoogle} onLoginUsingMicrosoft={onLoginUsingMicrosoft} loading={loading} disabled={disabled} error={error} success={success} />
  );
};

export default LoginPage;
